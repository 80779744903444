import { parseISO } from 'date-fns/parseISO';
import { GLOBAL } from 'saddlebag-browser';
import Localisation, {
  currency as currencyFeatures,
  number as numberFeatures,
  date as dateFeatures,
} from 'saddlebag-localisation';

import fallbackCldr from './cldr.json';

import type { LocalisationHelpers } from '@skyscanner-internal/falcon-shared-types/types/components';

const getLocalisationHelpers = (cldrData = {}): LocalisationHelpers => {
  Localisation.enhance(currencyFeatures, numberFeatures, dateFeatures);
  const localisation = Localisation(cldrData);

  return {
    formatDate: localisation.formatDate.bind(localisation),
    formatTime: localisation.formatTime.bind(localisation),
    formatDateTime: localisation.formatDateTime.bind(localisation),
    formatNumber: localisation.formatNumber.bind(localisation),
    formatCurrency: localisation.formatCurrency.bind(localisation),
    formatCurrencyFull: localisation.formatCurrency.bind(localisation),
    daysOfWeek: localisation.getDaysOfWeek(),
    weekStartsOn: localisation.getFirstDay(),
    formatDateNonlocalised:
      localisation.formatDateNonlocalised.bind(localisation),
  };
};

const getCldrFromWindow = (): any => {
  const $window = GLOBAL.getWindow();
  // eslint-disable-next-line no-underscore-dangle
  return $window && $window.__internal ? $window.__internal.cldr : fallbackCldr;
};

// Returns a formatted date string from an ISO8061 string e.g.
const getLocalFormattedDateFromISO = ({
  cldrData,
  format = 'long',
  isoDate,
}: {
  cldrData?: {};
  isoDate: string;
  format?: string;
}) => {
  const parsedIsoDate = parseISO(isoDate);
  const localisationHelper = getLocalisationHelpers(
    cldrData || getCldrFromWindow(),
  );

  return localisationHelper.formatDate(parsedIsoDate, 'long');
};

export default getLocalisationHelpers;
export { getLocalFormattedDateFromISO };
